/* App.css */
body{
  background-color: #EDC764;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo_Header{
  width: 120px;
}

 p{
  margin: 0;
  padding: 0;
 }
  .container{
    padding: 0px 40px;
  }
  .staking{
    margin-top: 50px;
    padding-top: 50px;
  }
  .row{
    display: flex;
  }
  .col-md-12{
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .staking_title{
    flex: 0 0 100%;
  }
  .staking_title h4{
    font-size: 64px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 30px 0px;
    border-top: 1px solid #C7A449;
    display: block;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    /* padding: 0 15px; */
}
  .common_item{
    border-top: 1px solid #C7A449;
    border-right: 1px solid #C7A449;
    border-bottom: 1px solid #C7A449;
    /* padding: 0px 30px 30px 0px; */
  }

  .tab_header {
    display: flex;
    column-gap: 30px;
}

  .stakingTab{
    padding: 20px 0px;
  }
  .stakingTab h3{
    color: #353535;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
  .unstakingTab{
    padding: 20px 0px;
  }
  .unstakingTab h4{
    color: #353535;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
  .ballanced {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #C7A449;
    padding-right: 30px;
    padding-top: 20px;

}
  .total_staked{
 
  }
  .total_staked p{
    color: #000000;
  }
  .total_staked h2{
    font-size: 32px;
    font-weight: 500;
  }
  .total_staked h2 span{
    color: #484848;
  }
  .total_pool{}
  .total_pool p{}
  .total_pool h2{
    font-size: 32px;
    font-weight: 500;
  }
  .total_pool h2 span{
    color: #484848;
  }

.content_pera{
  padding-bottom: 25px;
  flex: 0 0 75%;
  max-width: 75%;
}
.content_pera p{
  font-size: 16px;
  color: #000000;
}
.last_items{
  border-top: 1px solid #C7A449;
  padding-right: 30px;
  padding-bottom: 30px;
}
.my_balance{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.balance_sheet{}
.balance_sheet h5,.balance_price h5{
  font-size: 17px;
  color: black;
  font-weight: 400;
}
.balance_price{}
.balance_price{}
.balance_price h5{}

.apeField{

  width: 97%;
  padding: 25px 0px 15px 20px;
  border: 0;
  display: flex;
  background: #D4B668;
  border-bottom: 2px solid #9E833C;
}

input[type="text"]::placeholder {
  text-align: right; /* Align placeholder text to the right */
  font-size: 32px;
  font-weight: 400;
  color: #484848;
  line-height: inherit;
  padding: 10px;
}
input[type="text"]:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove box shadow */
}
.stakeButton{
  width: 100%;
  background-color:#000000;
  color: white;
  border: 0;
  outline: 0;
  padding: 16px 24px 16px 24px;
  margin-top: 30px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: 0.5s ease-in;
  -webkit-transition: 0.5s ease-in;
  -moz-transition: 0.5s ease-in;
  -ms-transition: 0.5s ease-in;
  -o-transition: 0.5s ease-in;
}
.stakeButton:hover{
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
}
@media (max-width:992px) {
  .total_pool h2 {
    font-size: 21px;

}
.total_staked h2 {
  font-size: 21px;
  
}
.content_pera {
  padding-bottom: 20px;
  flex: 0 0 95%;
  max-width: 95%;
}
  
}

@media (max-width:767.98px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
    /* padding: 0 15px; */
}

  
}
@media (max-width:575.98px) {
  .container {
    padding: 0px 15px;
}
.ballanced {

  padding-right: 20px;
  padding-top: 15px;
}
.total_staked h2 {
  font-size: 19px;
} 
.total_pool h2 {
  font-size: 19px;
}
.apeField {
  width: 93%;

}
}

.rightHeader{
  display: flex;
  /* align-items: center; */
  padding-top: 15px;
  justify-content: space-between;
}
.right_common_item{

  border-top: 1px solid #C7A449 ;

  padding: 0px 20px;
}
.token_price{}
.token_price p{}
.token_price h4{
  font-size: 22px;
  font-weight: 500;
}
.amount_claimed{}
.amount_claimed p{}
.amount_claimed h4{
  font-size: 18px;
  font-weight: 500;
}
.rightAmount{}
.rightAmount h2{
  display: flex;
  align-items: center;
  font-size: 60px;
  column-gap: 30px;
  font-weight: 400;
  margin-top: -10px;
}
.rightAmount h2 span{
  display: flex;
}
.rightInput{
  margin-top: -50px;
}
.last_items2{
  padding-bottom: 20px;
  border-top: 0px;
}
.balanced2{
  border-top: 0px;
}



.tabs-container {
 
  overflow: hidden;
}

.tab-buttons {
  display: flex;
}

.tab-buttons button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.tab-buttons button.active {
  background-color: #ddd;
}

.col-md-2{
  flex: 0 0 100%;
  max-width: 100%;
}
/* Animation for tab content */
.wallet-button{
  background-color: #212121;
  padding: 16px 24px 16px 24px;
  border: 0;
  outline: 0;
  color: white;
  cursor: pointer;
}
.copyItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.copyrightText h4{
  font-size: 18px;
  font-weight: 500;
}
.copy_link{
  display: flex;
  column-gap: 15px;
}
.copy_link a{
  text-decoration: none;
  color: #000000;
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
}
.copy_link a:hover{
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.copyRight{
  padding-top: 80px;
  padding-bottom: 10px;
}
@media (max-width:992px) {
  .rightHeader {
    display: flex;

    flex-wrap: wrap;
}
.balanced2 {
  border-top: 0px;
  padding-right: 0;
}
.rightAmount h2 {

  font-size: 30px;
  column-gap: 10px;

}
.last_items2 {

  padding-right: 0px;
}
.rightAmount img{
  width: 40px;
}
.rightAmount h2{
  padding-bottom: 20px;
}
.staking_title h4 {
  font-size: 54px;

  padding: 20px 0px;

}
.row {
  display: flex;
  flex: wr;
  flex-wrap: wrap;
}
.col-md-6{
flex: 0 0 100%;
max-width: 100%;
}

.copyItem {

  justify-content: center;
}
.copyRight{
  padding-top: 40px;
  padding-bottom: 10px;
}
}
@media (max-width:767.98px) {
  .row {
    display: flex;
    flex: wr;
    flex-wrap: wrap;
}
.col-md-6{
  flex: 0 0 100%;
  max-width: 100%;
}
.staking_title h4 {
  font-size: 44px;

  padding: 20px 0px;

}
}
@media (max-width:575.98px) {
  .copy_link a {
font-size: 14px;
    padding-bottom: 15px;
}
.copy_link{
  flex-wrap: wrap;
  justify-content: center;
}
.copyrightText h4 {
  font-size: 14px;
line-height: 29px;
}
.copyRight{
  padding-top: 30px;
  padding-bottom: 5px;
}
.copyItem {

  text-align: center;
}
}

.tab_header{
  padding: 30px 0px;
}
/* Define transition properties for smooth animation */
.tab_header button {
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  color: #353535;
font-weight: 400;
font-size: 18px;
background: transparent;
border: 0;
outline: 0;
cursor: pointer;
padding-bottom: 8px;
}

/* Define styles for active tab */
.tab_header button.active {
  border-bottom: 1px solid black;
  color: #000000;


}
.connectAccount{
  color: white;
  text-align: right;
}
@media (max-width:992px) {
  .connectAccount{
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    text-align: left;
    padding-bottom: 40px;

  }
  
}

